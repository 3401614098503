// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capacity-building-unilever-js": () => import("./../../../src/pages/capacity-building-unilever.js" /* webpackChunkName: "component---src-pages-capacity-building-unilever-js" */),
  "component---src-pages-company-profile-js": () => import("./../../../src/pages/company-profile.js" /* webpackChunkName: "component---src-pages-company-profile-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-protection-catalogue-js": () => import("./../../../src/pages/data-protection-catalogue.js" /* webpackChunkName: "component---src-pages-data-protection-catalogue-js" */),
  "component---src-pages-data-protection-proctor-law-js": () => import("./../../../src/pages/data-protection-proctor-law.js" /* webpackChunkName: "component---src-pages-data-protection-proctor-law-js" */),
  "component---src-pages-delware-tech-js": () => import("./../../../src/pages/delware-tech.js" /* webpackChunkName: "component---src-pages-delware-tech-js" */),
  "component---src-pages-digital-js": () => import("./../../../src/pages/digital.js" /* webpackChunkName: "component---src-pages-digital-js" */),
  "component---src-pages-digital-usecase-js": () => import("./../../../src/pages/digital-usecase.js" /* webpackChunkName: "component---src-pages-digital-usecase-js" */),
  "component---src-pages-digitize-process-automation-js": () => import("./../../../src/pages/digitize-process-automation.js" /* webpackChunkName: "component---src-pages-digitize-process-automation-js" */),
  "component---src-pages-home-home-js": () => import("./../../../src/pages/home/home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-home-insight-insight-js": () => import("./../../../src/pages/home/insight/insight.js" /* webpackChunkName: "component---src-pages-home-insight-insight-js" */),
  "component---src-pages-home-maximizing-profit-maximizing-profit-js": () => import("./../../../src/pages/home/maximizing-profit/maximizing-profit.js" /* webpackChunkName: "component---src-pages-home-maximizing-profit-maximizing-profit-js" */),
  "component---src-pages-home-partners-partners-js": () => import("./../../../src/pages/home/partners/partners.js" /* webpackChunkName: "component---src-pages-home-partners-partners-js" */),
  "component---src-pages-home-people-power-people-power-js": () => import("./../../../src/pages/home/people-power/people-power.js" /* webpackChunkName: "component---src-pages-home-people-power-people-power-js" */),
  "component---src-pages-home-process-transformation-process-transformation-js": () => import("./../../../src/pages/home/process-transformation/process-transformation.js" /* webpackChunkName: "component---src-pages-home-process-transformation-process-transformation-js" */),
  "component---src-pages-home-risk-assessment-risk-assessment-js": () => import("./../../../src/pages/home/risk-assessment/risk-assessment.js" /* webpackChunkName: "component---src-pages-home-risk-assessment-risk-assessment-js" */),
  "component---src-pages-home-speed-precision-speed-precision-js": () => import("./../../../src/pages/home/speed-precision/speed-precision.js" /* webpackChunkName: "component---src-pages-home-speed-precision-speed-precision-js" */),
  "component---src-pages-home-team-team-js": () => import("./../../../src/pages/home/team/team.js" /* webpackChunkName: "component---src-pages-home-team-team-js" */),
  "component---src-pages-home-testimonial-testimonial-js": () => import("./../../../src/pages/home/testimonial/testimonial.js" /* webpackChunkName: "component---src-pages-home-testimonial-testimonial-js" */),
  "component---src-pages-human-capital-development-js": () => import("./../../../src/pages/human-capital-development.js" /* webpackChunkName: "component---src-pages-human-capital-development-js" */),
  "component---src-pages-human-capital-development-usecase-js": () => import("./../../../src/pages/human-capital-development-usecase.js" /* webpackChunkName: "component---src-pages-human-capital-development-usecase-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-post-js": () => import("./../../../src/pages/individual-post.js" /* webpackChunkName: "component---src-pages-individual-post-js" */),
  "component---src-pages-insights-posts-js": () => import("./../../../src/pages/insights-posts.js" /* webpackChunkName: "component---src-pages-insights-posts-js" */),
  "component---src-pages-intelligent-automation-js": () => import("./../../../src/pages/intelligent-automation.js" /* webpackChunkName: "component---src-pages-intelligent-automation-js" */),
  "component---src-pages-intelligent-automation-usecase-js": () => import("./../../../src/pages/intelligent-automation-usecase.js" /* webpackChunkName: "component---src-pages-intelligent-automation-usecase-js" */),
  "component---src-pages-managed-services-the-louvre-js": () => import("./../../../src/pages/managed-services-the-louvre.js" /* webpackChunkName: "component---src-pages-managed-services-the-louvre-js" */),
  "component---src-pages-network-security-audit-js": () => import("./../../../src/pages/network-security-audit.js" /* webpackChunkName: "component---src-pages-network-security-audit-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-proctor-design-usecase-js": () => import("./../../../src/pages/proctor-design-usecase.js" /* webpackChunkName: "component---src-pages-proctor-design-usecase-js" */),
  "component---src-pages-risk-management-js": () => import("./../../../src/pages/risk-management.js" /* webpackChunkName: "component---src-pages-risk-management-js" */),
  "component---src-pages-security-usecase-js": () => import("./../../../src/pages/security-usecase.js" /* webpackChunkName: "component---src-pages-security-usecase-js" */),
  "component---src-pages-transformation-js": () => import("./../../../src/pages/transformation.js" /* webpackChunkName: "component---src-pages-transformation-js" */),
  "component---src-pages-transformation-usecase-js": () => import("./../../../src/pages/transformation-usecase.js" /* webpackChunkName: "component---src-pages-transformation-usecase-js" */)
}

